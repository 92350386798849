<template>
  <div ref="blockRef">
    <div class="group relative overflow-hidden aspect-video lg:aspect-auto lg:h-screen">
      <UseWindowSize v-slot="{ width }">
        <video
          v-if="isLoaded"
          ref="video"
          :class="`h-full w-full ${block.is_contained ? 'object-contain' : 'object-cover'}`"
          autoplay
          muted
          loop
          @click="play"
          playsinline
          preload="auto"
          data-gsap-target="video"
        >
          <ClientOnly>
            <source :src="videoSrc(width)" type="video/mp4">
          </ClientOnly>
        </video>

        <div
          class="absolute left-4 bottom-4 z-[1] flex items-center gap-4 opacity-0 translate-y-8 transition-all duration-150 lg:left-10 lg:bottom-10 group-hover:opacity-100 group-hover:translate-y-0"
        >
          <button
            type="button"
            class="btn btn--rounded text-purple-dark bg-white shadow"
            @click="play"
            aria-label="Play/Pause video"
          >
            <span class="relative">
              <Icon
                :icon="isPlaying ? 'svgo-pause' : 'svgo-play'"
                class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 h-5 w-5"
              />
            </span>
          </button>

          <button
            type="button"
            class="btn btn--rounded text-purple-dark bg-white shadow"
            @click="mute"
            aria-label="Mute/Unmute video"
          >
            <span class="relative">
              <Icon
                :icon="isMuted ? 'svgo-unmute' : 'svgo-mute'"
                class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 h-5 w-5"
              />
            </span>
          </button>
        </div>
      </UseWindowSize>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UseWindowSize } from '@vueuse/components';
import { useElementVisibility } from '@vueuse/core';
import type { Video } from '~/statamic/types/block';

const props = defineProps<{
  block: Video;
}>();

let ctx: gsap.Context | null;
const video = ref();
const blockRef = ref();

const isMuted = ref(true);
const isLoaded = ref(false);
const isPlaying = ref(true);
const isVisible = useElementVisibility(blockRef);

const videoSrc = (windowWidth: number) => {
  if (props.block.vimeo_url_uhd_2k && windowWidth > 1920) {
    return props.block.vimeo_url_uhd_2k;
  }

  if (props.block.vimeo_url_hd_1080p && windowWidth > 1024) {
    return props.block.vimeo_url_hd_1080p;
  }

  return props.block.vimeo_url_sd_540p;
};

const mute = () => {
  video.value.muted = !video.value.muted;
  isMuted.value = video.value.muted;
};

const play = () => {
  video.value[isVideoPlaying() ? 'pause' : 'play']();
  isPlaying.value = isVideoPlaying();
};

const isVideoPlaying = () => {
  return !!(video.value.currentTime > 0 && !video.value.paused && !video.value.ended && video.value.readyState > 2);
};

watch(() => isVisible.value, () => {
  isLoaded.value = true;
});

onMounted(() => {
  ctx = useGsap.context((self) => {
    if (typeof self.selector !== 'function') return;

    const videoGsap = self.selector('[data-gsap-target="video"]')[0];

    useScrollTrigger.create({
      pin: isDesktop(),
      start: `top ${isDesktop() ? 'top' : 'bottom'}`,
      end: 'bottom bottom-=25%',
      trigger: blockRef.value,
    });

    if (videoGsap) {
      useGsap.from(videoGsap, {
        scale: 0.75,
        borderRadius: '2rem',
        scrollTrigger: {
          start: 'top center',
          trigger: blockRef.value,
          onEnter: () => videoGsap.play(),
          onEnterBack: () => videoGsap.play(),
          onLeave: () => videoGsap.pause(),
          onLeaveBack: () => videoGsap.pause(),
        },
      });
    }
  }, blockRef.value);
});

onUnmounted(() => {
  ctx?.revert();
});
</script>
